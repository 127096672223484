import React from "react";
import './certificate.css'
import ACE from '../../assets/ace.png'
import PDE from '../../assets/pde.png'

const Certificates = () => {
    return(
        <section id='certificate'>
            <span className="certTitle">Global Certifications</span>
            <span className="certDesc">Explore my recognized certifications  and credentials that validate my expertise in Data Engineer. Each certificate represents a milestone in my professional journey and showcases my commitment to continuous learning and skill development.</span>
            <div className="certBars">
                <div className="certBar">
                    <img src={ACE} alt="Google Cloud Certified Associate Cloud Engineer" className="certBarImg"/>
                    <div className="certBarText">
                    <h2>Google Cloud Certified Associate Cloud Engineer</h2>

                    <p>Demonstrating proficiency in designing and implementing data processing systems and solutions on Google Cloud Platform.</p>

                    </div>
                </div>
                <div className="certBar">
                    <img src={PDE} alt="Google Cloud Certified Professional Data Engineer" className="certBarImg"/>
                    <div className="certBarText">
                        <h2>Google Cloud Certified Professional Data Engineer</h2>
                        <p>Demonstrating proficiency in designing and implementing data processing systems and solutions on Google Cloud Platform.</p>
                    </div>
                </div>
            </div>
            
        </section>

    );
}

export default Certificates;