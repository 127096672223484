import React from 'react'
import './introduction.css'
import ctr from '../../assets/ctr_bg.png'

const Introduction = () => {
  return (
    <section id="introduction">
        <div className='introContent'> 
        <span className='hello'>Hello,</span>
        <span className='introText'>I'm <span className='introName'>Thaneesh Reddy</span><br/>Data Engineer</span>
        <p className="introPara">I am certified data engineer proficient in ETL, Pyspark, and Data Modeling,<br/> adept at architecting scalable solutions. Experienced in SQL, Python, big <br/>data technologies, and cloud platforms for driving data-driven innovation. </p>
        <button className='hireMe'> Want to collaborate / hire me?</button>
        </div>
        
        <img src={ctr} alt="profile" className="bg" />
    </section>
  )
}

export default Introduction