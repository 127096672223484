import Navbar from "./components/navbar/navbar";
import Introduction from "./components/introduction/introduction";
import Certificates from "./components/Certificates/certificate";
function App() {
  return (
    <div  className="App"> 
    <Navbar/>
    <Introduction/>
    <Certificates/>
    </div>
  );
}

export default App;
