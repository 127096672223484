import React from 'react';
import './navbar.css';
import logo from '../../assets/T_logo.png';
import contactUs from '../navbar/../../assets/Contact_me.png'
import { Link } from 'react-scroll';

const Navbar = () => {
  return (
    <nav className='navbar'>
        <img src={logo} alt='logo' className='logo'/>
        <div className='desktopMenu'>
            <Link className="desktopMenuListItem">Home</Link>
            <Link className="desktopMenuListItem">Portfolio</Link>
            <Link className="desktopMenuListItem">About</Link>
            <Link className="desktopMenuListItem">Resume</Link>
        </div>
        <button className='desktopMenuButton'> 
        <img src={contactUs} alt="Contact Us" className="desktopMenuImg" />
        </button>
        

    </nav>
  )
}

export default Navbar